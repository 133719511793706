import React, { useState, useEffect } from "react";
import { GoDotFill } from "react-icons/go";
import { IoIosCheckmarkCircle } from "react-icons/io";
import avatar from "../../assets/lady.jpg"; // Assuming this is a default image
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setSearchText } from "../../redux-store/postSearch/searchSlice";

const ChatPersonDirect = ({ userDetails, postDescriptionall }) => {
  const navigate = useNavigate();
  const [userLastmsg, setUserLastMessage] = useState("");
  const [expandedMessages, setExpandedMessages] = useState({});
  const dispatch = useDispatch();

  const capitalizeFirstLetter = (string) => {
    if (!string) return "";
    const formattedString = string.replace(/-/g, " ");
    return formattedString.charAt(0).toUpperCase() + formattedString.slice(1);
  };

  const handleTagClick = (tag) => {
    dispatch(setSearchText(tag));
    navigate("/explore");
  };

  return (
    <div className="py-3 bg-white rounded-xl mb-3 w-full px-8">
      {userDetails && (
        <div className="flex">
          <Link to={`/user/${userDetails.id}`}>
            <img
              className={`w-12 h-12 rounded-full object-cover text-[#E7831D] ${
                userDetails.type === "employer"
                  ? "ring-2 ring-[#E7831D]"
                  : "ring-2 ring-gray-700"
              }`}
              src={
                userDetails.profile_image !== "default.png"
                  ? `https://bocaendboca.s3.us-east-2.amazonaws.com/${userDetails.profile_image}`
                  : avatar
              }
              alt="User Avatar"
            />
          </Link>

          <div className="flex flex-col ml-2">
            <Link to={`/user/${userDetails.id}`}>
              <span className="text-lg font-bold text-gray-600 cursor-pointer">
                {userDetails.first_name} {userDetails.last_name}
              </span>
            </Link>

            <span
              className={`text-sm flex items-center ${
                userDetails.type === "job-seeker"
                  ? "text-black"
                  : "text-[#E7831D]"
              }`}
            >
              <span
                className={`text-sm text-[#5B5B5B] ${
                  userDetails.username === "@premium" ||
                  userDetails.username === "@Test"
                    ? "text-[#5B5B5B]"
                    : ""
                }`}
              >
                {userDetails.username}
              </span>
              <GoDotFill className="ml-1 text-xs mr-1" />
              {capitalizeFirstLetter(userDetails.type)}
              <IoIosCheckmarkCircle className="ml-1 text-lg" />
            </span>
          </div>
        </div>
      )}

      <div className="mt-3 ml-14 mb-3">
        <div>
          {postDescriptionall.split("\n").map((line, index) => (
            <React.Fragment key={index}>
              {line.split(/(\s+)/).map((part, i) =>
                part.startsWith("#") ? (
                  <span
                    key={i}
                    className="text-[#E7831D] font-bold cursor-pointer transition-transform ease-in-out duration-300"
                    onClick={() => handleTagClick(part)}
                  >
                    {part}
                  </span>
                ) : (
                  part
                )
              )}
              <br />
            </React.Fragment>
          ))}
        </div>
      </div>
      <hr />
    </div>
  );
};

export default ChatPersonDirect;
