import React from "react";
import { IoClose } from "react-icons/io5";
import { Modal, Input } from "antd";
import { Toaster, toast } from "sonner";
import { QRCodeSVG } from "qrcode.react"; // Import the QRCodeSVG component

const SharePostPopUp = ({ copymodalVisible, setcopyModalVisible, url }) => {
  const handleCopy = () => {
    navigator.clipboard.writeText(url);
    toast.success("URL copied to clipboard!");
    setcopyModalVisible(false);
  };

  return (
    <>
      <Toaster position="top-right" />
      <Modal
        visible={copymodalVisible}
        footer={null}
        onCancel={() => setcopyModalVisible(false)}
        centered
        closeIcon={<IoClose className="text-gray-50" />}
        className="custom-modal" // Ensure you use a class for custom styling
      >
        <div className="flex flex-col space-y-5 items-center text-center lg:p-8 md:p-6 p-4">
          <p className="text-lg font-medium text-gray-600">
            Copy the URL and share it with others!
          </p>
          {/* QR Code */}
          {/* <QRCodeSVG value={url} size={200} className="mb-5" /> */}
          {/* URL Input */}
          <Input
            value={url}
            disabled
            readOnly
            className="text-center bg-gray-100 rounded-lg border-none focus:ring-2 focus:ring-gray-300"
            style={{ width: "100%" }}
          />
          {/* Copy Button */}
          <button
            className="p-3 bg-[#404041] text-white font-bold rounded-full shadow-lg text-md transition-transform transform hover:scale-105 hover:shadow-xl w-1/2"
            onClick={handleCopy}
          >
            Copy URL
          </button>
        </div>
      </Modal>
    </>
  );
};

export default SharePostPopUp;
