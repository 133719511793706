import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom"; // Assuming you're using react-router for routing
import axios from "axios";
import ChatComponent from "../components/messagePage/ChatComponent";
import ChatComponentdirect from "../components/messagePage/ChatComponentdirect";
import ShimmerChatPersonDirect from "../components/loading/ShimmerChatPersonDirect";
import ShimmerChatsList from "../components/loading/ShimmerChatsList";

const DirectChat = () => {
  const { id } = useParams();
  const { userId } = useParams();
  const [selectedChat, setSelectedChat] = useState(null);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [postDescriptionall, setPostDescription] = useState("");

  const [userDetails, setUserDetails] = useState(null);
  console.log(userDetails, "userDetails");

  const baseUrl = process.env.REACT_APP_API_BASE_URL;

  const fetchChatData = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${baseUrl}/api/v1/customer/chat/conversation?channel_id=${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const channelDetails = response.data.content.Channel_details;

      // Check if channelDetails is null
      if (channelDetails) {
        setSelectedChat(channelDetails);
        setPostDescription(channelDetails.post.description);
      } else {
        // If Channel_details is null, you can choose to set default states or do nothing
        setSelectedChat(null); // or set a default value
        setPostDescription(""); // or set a default value
      }

      setLoading(false);
    } catch (error) {
      console.error("Error fetching chat data:", error);
      setLoading(false);
    }
  };

  const fetchUserDetails = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${baseUrl}/api/v1/user/details/${userId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setUserDetails(response.data.content);
    } catch (error) {
      console.error("Error fetching user details:", error);
      setError("Failed to load user details.");
    }
  };

  useEffect(() => {
    fetchChatData();
    fetchUserDetails();
  }, [id, userId]);

  if (loading) {
    return (
      <div>
        <ShimmerChatPersonDirect />
        <ShimmerChatsList />
      </div>
    );
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="w-full h-screen">
      <ChatComponentdirect
        Channel_details={selectedChat}
        postDescriptionall={postDescriptionall}
        OpenChat={() => {}}
        userDetails={userDetails}
      />
    </div>
  );
};

export default DirectChat;
