import React, { useState, useEffect } from "react";
import moment from "moment";
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Transition,
} from "@headlessui/react";
import "./modal.css";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { setPostDetails } from "../../redux-store/postEdit/postSlice";

import { setSearchText } from "../../redux-store/postSearch/searchSlice";

import { IoIosBookmark } from "react-icons/io";
import { BsThreeDots } from "react-icons/bs";
import PostModal from "./PostModal";
import { Modal } from "antd";
import { IoBookmarkSharp } from "react-icons/io5";
import { MdCloseFullscreen } from "react-icons/md";
import { Spin } from "antd";
import Loading from "../loading/Loading";
import { Link, useLocation, useNavigate } from "react-router-dom";
import avatar from "../../assets/lady.jpg";
import { FaRegBookmark } from "react-icons/fa";
const BASE_URL = "https://bocaenboca.goodcoderz.com/";
import { GoDotFill } from "react-icons/go";
import commentIcon from "../../assets/comment.svg";
import bookmark from "../../assets/Bookmarks.svg";
import share from "../../assets/share.svg";
import { LuShare } from "react-icons/lu";
import commentWhite from "../../assets/commentwhite.svg";
import bookMarkwhite from "../../assets/bookmarkwhite.svg";
import PleaseLoginModal from "../../pages/PleaseLoginModal";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import User from "./User";
import { IoExpand, IoArrowBack, IoArrowForward } from "react-icons/io5";
import { FaUserCircle } from "react-icons/fa";
import { IoClose } from "react-icons/io5";
import MenuOptionscopy from "./MenuOptionscopy";
import translations from "../../languages";
import EditPostModalComponent from "../mini-components/EditPostModalComponent";
import VideoGallery from "../mini-components/VideoGallery";
import ImageGallery from "../mini-components/ImageGallery";
import PostDescription from "../mini-components/PostDescription";
import MediaModal from "../mini-components/MediaModal";
import SharePostPopUp from "../share/SharePostPopUp";

const Posts = ({
  postdatas,
  handleBookmark,
  handleReportPost,
  createChatChannel,
  channelLoading,
  handleDeletePost,
  loading,
  bookmarkedPosts,
  setFetching,
  fetchPosts,
  setPostLimit,
  fetchData,
  setLoadingpost,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [bookMarkFetching, setBookMarkFetching] = useState({});
  const [error, setError] = useState(null);
  const [displayedPosts, setDisplayedPosts] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalImage, setModalImage] = useState("");
  const [modalData, setModalData] = useState(null);
  const [userExists, setUserExists] = useState(false);
  const [isLoginModalVisible, setIsLoginModalVisible] = useState(false);
  const [modalIndex, setModalIndex] = useState(null);
  const selectedLanguage = useSelector((state) => state.language.language);
  const [iseditModalVisible, setIseditModalVisible] = useState(false);
  const [copymodalVisible, setcopyModalVisible] = useState(false);
  const [url, setUrl] = useState("");
  const postDetails = useSelector((state) => state.post.postDetails);
  const texts = translations[selectedLanguage].types;
  const options = translations[selectedLanguage].actions;

  const handleTagClick = (tag) => {
    dispatch(setSearchText(tag));
  };

  useEffect(() => {
    if (postDetails?.id) {
      setIseditModalVisible(true);
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  }, [postDetails]);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      setUserExists(true);
    }
  }, []);

  const SvgIcon = ({ src, className, ...props }) => (
    <img src={src} className={className} alt="" {...props} />
  );

  useEffect(() => {
    setDisplayedPosts(
      postdatas.map((post) => ({ ...post, bookMarked: false }))
    );
  }, [postdatas]);

  const handleBookmarkClick = (userId) => {
    handleBookmark(userId);
  };
  const openModal = (post, index) => {
    setModalData(post);
    setModalIndex(index); // Store the index
    setIsModalOpen(true);
  };

  // Update the handleBookMark function inside the Modal
  const handleModalBookmark = async () => {
    if (!modalData || modalIndex === null) return;
    await handleBookMark(modalData.id, modalIndex);
  };
  const closeModal = () => {
    setIsModalOpen(false);
    setModalData(null);
  };

  const handleBookMark = async (postId, index) => {
    if (!userExists) {
      setIsLoginModalVisible(true);
      return;
    }
    setBookMarkFetching((prevState) => ({ ...prevState, [postId]: true }));
    setError(null);
    try {
      let formData = new FormData();
      formData.append("post_id", postId);

      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/user/bookmark/store`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer  ${localStorage.getItem("token")}`,
          },
          body: formData,
        }
      );
      if (response.status >= 200 && response.status < 300) {
        setDisplayedPosts((prevPosts) => {
          const updatedPosts = [...prevPosts];
          updatedPosts[index].bookMarked = !updatedPosts[index].bookMarked;
          return updatedPosts;
        });
      } else {
        const res = await response.json();
        setError(res.message);
        return;
      }
    } finally {
      setBookMarkFetching((prevState) => ({ ...prevState, [postId]: false }));
    }
  };

  const handleEditPost = (post) => {
    dispatch(
      setPostDetails({
        id: post.id,
        description: post.description,
        photos: post.images,
        videos: post.videos,
      })
    );

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setIseditModalVisible(true);
  };
  const capitalizeFirstLetter = (string) => {
    if (!string) return "";

    const formattedString = string.replace(/-/g, " ");

    return formattedString
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };
  useEffect(() => {
    // Check if postId and editSource exist in localStorage
    const postId = localStorage.getItem("postId");
    const editSource = localStorage.getItem("editSource");

    if (postId && editSource === "edited from home") {
      // If they exist, set the modal visibility to true
      setIseditModalVisible(true);

      // Optionally, clear these items from localStorage after use
      localStorage.removeItem("postId");
      localStorage.removeItem("editSource");
    }
  }, [setIseditModalVisible]);
  const handleshare = (id) => {
    setUrl(`${window.location.origin}/post/${id}`);
    setcopyModalVisible(true);
    // navigate(`/post/${id}`);
  };
  return (
    <div className="">
      {displayedPosts.map((post, index) => (
        <div
          key={post.id}
          className="w-full scrollbar-hide mt-2 "
          style={{
            backgroundColor:
              post.user?.username === "@premium"
                ? "#8b551e"
                : post.user.username === "@Test"
                ? "#7e7d7e"
                : "white",

            color:
              post.user.username === "@premium" ||
              post.user.username === "@Test"
                ? "white"
                : "black",
            padding:
              post.user.username === "@premium"
                ? "0"
                : post.user.username === "@Test"
                ? "0"
                : "0 0rem",
            borderRadius:
              post.user.username === "@premium" ||
              post.user.username === "@Test"
                ? "0"
                : "0.5rem",
            paddingTop: post.user.username === "@premium" ? "2px" : "0",
            paddingBottom: post.user.username === "@premium" ? "2px" : "0",
            marginBottom: post.user.username === "@Test" ? "10px" : "0",
          }}
        >
          <div
            className={`flex w-full ${
              post.user.username === "@premium" ? " px-8 mt-6" : "px-8 mt-10"
            }`}
          >
            {post.user.profile_image !== "default.png" && (
              <img
                className={`w-12 h-12 rounded-full p-0 object-cover text-[#E7831D] cursor-pointer ${
                  post.user.type === "employer"
                    ? "ring-2 ring-[#E7831D]"
                    : "ring-2 ring-gray-700"
                }`}
                onClick={() => navigate(`/user/${post.user.id}`)}
                src={
                  post.user.profile_image !== "default.png" &&
                  post.user.profile_image
                    ? `https://bocaendboca.s3.us-east-2.amazonaws.com/${post.user.profile_image}`
                    : avatar
                }
                alt="User Avatar"
                style={{ aspectRatio: "1 / 1", objectFit: "cover" }}
              />
            )}
            {post.user.profile_image === "default.png" && (
              <FaUserCircle
                className={`w-12 h-12 text-[2rem] rounded-full p-0 object-cover text-gray-300 cursor-pointer ${
                  post.user.type === "employer"
                    ? "ring-2 ring-[#E7831D]"
                    : "ring-2 ring-gray-700"
                }`}
                onClick={() => navigate(`/user/${post.user.id}`)}
              />
            )}
            <div className="flex flex-col ml-2">
              <Link
                to={`/user/${post.user.id}`}
                className={`text-lg  font-bold text-[#5B5B5B] ${
                  post.user.username === "@premium" ||
                  post.user.username === "@Test"
                    ? "text-white"
                    : ""
                }`}
              >
                {post.user.first_name} {post.user.last_name}
              </Link>
              <span
                className={`lg:text-sm md:text-sm text-xs  flex items-center ${
                  post.user.type === "job-seeker"
                    ? "text-black"
                    : "text-[#E7831D]"
                }`}
              >
                <span
                  className={`text-sm   text-[#5B5B5B] ${
                    post.user.username === "@premium" ||
                    post.user.username === "@Test"
                      ? "text-white"
                      : ""
                  }`}
                >
                  {" "}
                  {post.user.username}{" "}
                </span>
                <GoDotFill className="ml-1 text-xs mr-1" />
                {selectedLanguage === "English"
                  ? capitalizeFirstLetter(post.user.type)
                  : capitalizeFirstLetter(post.user.type_sp)}

                <IoIosCheckmarkCircle className="ml-1 text-lg" />
              </span>
            </div>
            {/* <span className="text-gray-600 ml-2 block md:hidden lg:hidden text-xs  mt-2">
              {post.user.username}
            </span> */}
            <MenuOptionscopy
              userExists={userExists}
              post={post}
              options={options}
              loading={loading}
              setIsLoginModalVisible={setIsLoginModalVisible}
              handleEditPost={handleEditPost}
              handleDeletePost={handleDeletePost}
              handleReportPost={handleReportPost}
              handleBookmarkClick={handleBookmarkClick}
            />
          </div>
          <PostDescription
            description={post.description}
            handleTagClick={handleTagClick}
          />
          <ImageGallery post={post} openModal={openModal} />
          {/* <VideoGallery post={post} openModal={openModal} /> */}

          <div className="flex px-0 w-44 ml-auto mt-14 mb-5 mr-5">
            {localStorage.getItem("userId") !== post.user.id && (
              <>
                {!userExists ? (
                  <SvgIcon
                    src={
                      post.user.username === "@premium" ||
                      post.user.username === "@Test"
                        ? commentWhite
                        : commentIcon
                    }
                    className="h-6 w-1/4 shrink-0 hover:scale-95"
                    aria-hidden="true"
                    onClick={() => setIsLoginModalVisible(true)}
                  />
                ) : (
                  <div>
                    {channelLoading[post?.id] ? (
                      <Spin className="ml-auto w-2/4" />
                    ) : (
                      post?.user && (
                        <SvgIcon
                          src={
                            post.user.username === "@premium" ||
                            post.user.username === "@Test"
                              ? commentWhite
                              : commentIcon
                          }
                          className="h-6 shrink-0 hover:scale-105 cursor-pointer"
                          aria-hidden="true"
                          onClick={() =>
                            createChatChannel(post?.id, post?.user?.id)
                          }
                        />
                      )
                    )}
                  </div>
                )}
              </>
            )}
            {bookMarkFetching[post.id] && <Spin className="ml-auto w-2/4" />}
            {!bookMarkFetching[post.id] && (
              <>
                {/* Check if post is bookmarked initially or after clicking */}
                {post.bookmark_status || post.bookMarked ? (
                  <IoBookmarkSharp
                    className="ml-auto w-2/4 text-2xl cursor-pointer hover:scale-105"
                    onClick={(e) => {
                      e.stopPropagation();
                      // handleBookMarkdelete(post.id, index);
                    }}
                  />
                ) : (
                  <SvgIcon
                    src={
                      post.user.username === "@premium" ||
                      post.user.username === "@Test"
                        ? bookMarkwhite
                        : bookmark
                    }
                    className="h-6 w-2/4 hover:scale-105 ml-auto cursor-pointer"
                    aria-hidden="true"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleBookMark(post.id, index);
                    }}
                  />
                )}
              </>
            )}

            {/* {post.bookMarked || location.pathname.includes("/bookmarks") ? (
              <IoBookmarkSharp className="ml-auto w-2/4 text-2xl cursor-pointer hover:scale-105" />
            ) : null} */}

            <LuShare
              className={`w-1/4 text-2xl text-gray-700 cursor-pointer ${
                post.user.username === "@premium" ||
                post.user.username === "@Test"
                  ? "text-white"
                  : ""
              } `}
              onClick={() => handleshare(post.id)}
            />
          </div>

          {post.user.username !== "@premium" &&
            post.user.username !== "@Test" && <hr />}
        </div>
      ))}

      <MediaModal
        isModalOpen={isModalOpen}
        closeModal={closeModal}
        modalData={modalData}
        handleTagClick={handleTagClick}
      />
      <EditPostModalComponent
        setIseditModalVisible={setIseditModalVisible}
        iseditModalVisible={iseditModalVisible}
        fetchPosts={fetchPosts}
        fetchData={fetchData}
        setPostLimit={setPostLimit}
        setLoadingpost={setLoadingpost}
      />
      {isLoginModalVisible && (
        <PleaseLoginModal
          visible={isLoginModalVisible}
          onCancel={() => setIsLoginModalVisible(false)}
        />
      )}
      {copymodalVisible && (
        <SharePostPopUp
          url={url}
          copymodalVisible={copymodalVisible}
          setcopyModalVisible={setcopyModalVisible}
        />
      )}
    </div>
  );
};

export default Posts;
