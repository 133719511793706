import React, { useState } from "react";
import { FaUserCircle } from "react-icons/fa";
import moment from "moment";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import translations from "../../languages";

const NotificationCards = ({
  notifications,
  list,
  showAll,
  setShowAll,
  toggleShowAll,
}) => {
  const animations = {
    initial: { scale: 0, opacity: 0 },
    animate: { scale: 1, opacity: 1, originY: 0 },
    exit: { scale: 0, opacity: 0 },
    transition: { type: "spring", stiffness: 350, damping: 40 },
  };

  const selectedLanguage = useSelector((state) => state.language.language);
  const texts = translations[selectedLanguage].buttons;
  const [type, setType] = useState();
  const navigate = useNavigate();
  const limitedNotifications = showAll
    ? notifications
    : notifications.slice(0, list);

  const capitalizeFirstLetter = (string) => {
    if (!string) return "";

    const formattedString = string.replace(/-/g, " ");

    return formattedString
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const handleNotificationClick = async (notification) => {
    try {
      const token = localStorage.getItem("token");
      const baseUrl = process.env.REACT_APP_API_BASE_URL;

      // Check if the title starts with "New Post Created by"
      if (notification.title.startsWith("New Post Created by")) {
        // Redirect to the post page with the updates_id
        navigate(`/post/${notification.updates_id}`);
      } else if (notification.title === "Bookmarked your profile") {
        navigate(`/user/${notification.by_user}`);
      } else {
        navigate(`/dm/${notification.channel_id}/${notification.by_user}`);
      }

      // Mark the notification as read
      const response = await axios.put(
        `${baseUrl}/api/v1/customer/notification/read/${notification.id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response.data);
    } catch (error) {
      console.error("Error marking notification as read:", error);
    }
  };
  const getTimeAgo = (created_at) => {
    const now = moment(); // Current local time
    const createdAt = moment.utc(created_at); // Parse created_at as UTC

    // Calculate the difference in seconds
    const secondsDiff = now.diff(createdAt, "seconds");

    // If secondsDiff is negative, treat it as seconds ago
    const absoluteDiff = Math.abs(secondsDiff);

    const minutesDiff = Math.floor(absoluteDiff / 60);
    const hoursDiff = Math.floor(absoluteDiff / 3600);
    const daysDiff = Math.floor(absoluteDiff / 86400);
    const monthsDiff = Math.floor(absoluteDiff / 2592000); // ~30 days

    if (monthsDiff > 0) {
      return `${monthsDiff} month${monthsDiff > 1 ? "s" : ""} ago`;
    } else if (daysDiff > 0) {
      return `${daysDiff} day${daysDiff > 1 ? "s" : ""} ago`;
    } else if (hoursDiff > 0) {
      return `${hoursDiff} hour${hoursDiff > 1 ? "s" : ""} ago`;
    } else if (minutesDiff > 0) {
      return `${minutesDiff} minute${minutesDiff > 1 ? "s" : ""} ago`;
    } else {
      return `${absoluteDiff} second${absoluteDiff > 1 ? "s" : ""} ago`;
    }
  };

  const filteredNotifications = limitedNotifications.filter(
    (notification) => notification.description !== null
  );

  return (
    <div className="z-30">
      {filteredNotifications.map((notification, index) => (
        <div
          key={index}
          className={`p-4 rounded-2xl mb-4 w-full relative hover:scale-95 cursor-pointer   transition-transform ease-in-out duration-300 overflow-y-auto ${
            notification.is_active === 0 ? "bg-white" : "bg-gray-100"
          }`}
          onClick={() => handleNotificationClick(notification)}
        >
          <div className="flex">
            {notification.sender_user.profile_image !== "default.png" && (
              <img
                className={`w-10 h-10 rounded-full p-0 object-cover text-[#E7831D]  ${
                  notification.sender_user.type === "employer"
                    ? "ring-2 ring-[#E7831D]"
                    : "ring-2 ring-gray-700"
                }`}
                src={`https://bocaendboca.s3.us-east-2.amazonaws.com/${notification.sender_user.profile_image}`}
                alt="Profile"
              />
            )}
            {notification.sender_user.profile_image === "default.png" && (
              <FaUserCircle
                className={`w-10 h-10 text-3xl rounded-full p-0 object-cover text-gray-300  ${
                  notification.sender_user.type === "employer"
                    ? "ring-2 ring-[#E7831D]"
                    : "ring-2 ring-gray-700"
                }`}
              />
            )}
            <div className="flex flex-col ml-2">
              <span className="text-md font-bold text-gray-600">
                {notification.sender_user.first_name}{" "}
                {notification.sender_user.last_name}
              </span>
              <span
                className={`text-xs flex items-center ${
                  notification.sender_user.type === "job-seeker"
                    ? "text-black"
                    : "text-[#E7831D]"
                }`}
              >
                {capitalizeFirstLetter(notification.sender_user.type)}
                <IoIosCheckmarkCircle className="ml-1" />
              </span>
            </div>
            <div className="flex flex-grow justify-end text-xs">
              <p>{getTimeAgo(notification.created_at)}</p>
            </div>
          </div>

          {notification.description && (
            <div className="mt-2 flex flex-col ml-12 text-xs">
              {notification.description
                .slice(0, 180) // Truncate description to 180 characters
                .split("\n") // Split by new lines
                .map((line, index) => (
                  <div key={index}>
                    {line.split(" ").map((word, idx) => (
                      <React.Fragment key={idx}>
                        {word.startsWith("#") ? (
                          <span className="text-[#E7831D] font-bold">
                            {word}
                          </span>
                        ) : (
                          <span>{word}</span>
                        )}
                        {/* Add space after each word */}
                        {idx < line.split(" ").length - 1 && " "}
                      </React.Fragment>
                    ))}
                    <br />
                  </div>
                ))}
              {/* Add "..." if the description is longer than 180 characters */}
              {notification.description.length > 180 && "..."}
            </div>
          )}
        </div>
      ))}

      {notifications.length > 3 && !showAll && (
        <div className="relative inset-0 flex z-40 justify-center items-center">
          {/* Blurred background */}
          <div className="absolute inset-0 flex justify-center items-center">
            <div className="absolute z-40 bg-white blur-3xl h-64 w-full"></div>
          </div>

          {/* Button */}
          <button
            onClick={toggleShowAll}
            className="absolute z-40 text-lg cursor-pointer text-white rounded-full px-7 bg-[#404041] p-3 bottom-5 hover:bg-[#545455] hover:scale-95   font-bold"
          >
            {texts.showMore}
          </button>
        </div>
      )}
    </div>
  );
};

export default NotificationCards;
