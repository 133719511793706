import React, { useState, useEffect } from "react";
import { Modal } from "antd";
import { IoClose } from "react-icons/io5";
import { FaTimes } from "react-icons/fa";
import { MdOutlineAddPhotoAlternate } from "react-icons/md";
import { Toaster, toast } from "sonner";
import axios from "axios";
import avatar from "../../assets/avatarpost.png";
import { useDispatch, useSelector } from "react-redux";
import { IoIosCheckmarkCircle } from "react-icons/io";
import translations from "../../languages";
import { GoDotFill } from "react-icons/go";
import MainLogoLoader from "../loading/MainLogoLoader";
import { clearPostDetails } from "../../redux-store/postEdit/postSlice";

const EditPostModalComponent = ({
  iseditModalVisible,
  setIseditModalVisible,
  fetchPosts,
  fetchData,
  setLoadingpost,
  setPostLimit,
}) => {
  const postDetails = useSelector((state) => state.post.postDetails);
  const [userAvatar, setUserAvatar] = useState("");
  const [username, setUsername] = useState("");
  const [loadingava, setLoadingava] = useState(false);
  const [userDetails, setUserDetails] = useState("");
  const [description, setDescription] = useState(postDetails.description || "");
  const [mediaFiless, setmediaFiless] = useState([]);
  console.log(mediaFiless, "mediaFiless");
  const [loading, setLoading] = useState(false);
  const selectedLanguage = useSelector((state) => state.language.language);
  const texts = translations[selectedLanguage].buttons;
  const dispatch = useDispatch();

  useEffect(() => {
    if (postDetails) {
      setDescription(postDetails.description || "");

      const existingFiles = [
        ...(postDetails.photos || []),
        ...(postDetails.videos || []),
      ].map((file) => ({
        ...file,
        file: file.file,
        isFile: false,
      }));

      setmediaFiless(existingFiles);
    }
  }, [postDetails]);

  const handleMediaUploade = (e) => {
    const files = Array.from(e.target.files);
    const uploadedFiles = files.map((file) => ({
      file: file,
      type: file.type.startsWith("image") ? "image" : "video",
      isFile: true,
    }));
    setmediaFiless((prevFiles) => [...prevFiles, ...uploadedFiles]);
  };

  const removeMediaFile = (index) => {
    setmediaFiless((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };
  const handleSubmit = async () => {
    const token = localStorage.getItem("token");
    const baseURL = process.env.REACT_APP_API_BASE_URL;
    const url = `${baseURL}/api/v1/user/post/update/${postDetails.id}`;

    const formData = new FormData();
    setLoading(true);

    // Append description to form data
    formData.append("description", description);

    // Helper function to convert existing media URLs to Blob/File objects
    const fetchExistingMediaAsFile = async (url, type) => {
      try {
        const response = await fetch(url);
        const blob = await response.blob();
        const fileName = url.split("/").pop(); // Extract filename from URL
        return new File([blob], fileName, { type: blob.type });
      } catch (error) {
        console.error(`Error fetching existing media: ${error}`);
        return null;
      }
    };

    // Loop through media files and append accordingly
    for (const file of mediaFiless) {
      if (file.isFile && file.file instanceof File) {
        // Handle newly uploaded files
        if (file.type === "image") {
          formData.append("identity_images[]", file.file); // Add new images as binary
        } else if (file.type === "video") {
          formData.append("video[]", file.file); // Add new videos as binary
        }
      } else if (!file.isFile) {
        // Handle existing media (convert URL to binary)
        const fileAsBinary = await fetchExistingMediaAsFile(
          file.file,
          file.type
        );
        if (fileAsBinary) {
          if (file.type === "image") {
            formData.append("identity_images[]", fileAsBinary); // Add existing image as binary
          } else if (file.type === "video") {
            formData.append("video[]", fileAsBinary); // Add existing video as binary
          }
        }
      }
    }

    try {
      setLoadingpost(true);

      // Make the API call with formData
      const response = await axios.post(url, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.status === 200) {
        // Success: Post updated
        setLoading(false);
        toast.success("Post updated successfully!");
        fetchData();
        setLoadingpost(false);
        setIseditModalVisible(false);
        dispatch(clearPostDetails());
      } else {
        // Error: Handle non-200 responses
        setLoading(false);
        setLoadingpost(false);
        toast.error("Failed to update post");
        setIseditModalVisible(false);
        dispatch(clearPostDetails());
      }
    } catch (error) {
      // Error handling
      setLoading(false);
      setLoadingpost(false);
      toast.error("Failed to update post");
      console.error("Error updating post:", error);
      setIseditModalVisible(false);
      dispatch(clearPostDetails());
    }
  };

  useEffect(() => {
    const fetchUserDetails = async () => {
      setLoadingava(true);
      try {
        const token = localStorage.getItem("token");
        const baseURL = process.env.REACT_APP_API_BASE_URL;
        const userDetailsUrl = `${baseURL}/api/v1/user/details`;
        const response = await axios.get(userDetailsUrl, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setUserDetails(response.data.content);
        setUserAvatar(response.data.content.profile_image);
        setUsername(
          `${response.data.content.first_name} ${response.data.content.last_name}`
        );

        localStorage.setItem("userId", response.data.content.id);
        setLoadingava(false);
      } catch (error) {
        console.error("Error fetching user details:", error);
        setLoadingava(false);
      }
    };

    fetchUserDetails();
  }, []);

  const capitalizeFirstLetter = (string) => {
    if (!string) return "";
    const formattedString = string.replace(/-/g, " ");
    return formattedString
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  return (
    <>
      <Modal
        visible={iseditModalVisible}
        onCancel={() => setIseditModalVisible(false)}
        footer={null}
        width={700}
        closeIcon={<IoClose className="text-gray-50" />}
      >
        <div className="flex flex-col p-5">
          {loading && (
            <div className="absolute inset-0 flex items-center justify-center bg-white/50 bg-opacity-50 z-50">
              <MainLogoLoader />
            </div>
          )}

          <div className="flex flex-col sm:flex-row items-start">
            {loadingava ? (
              <div className="w-12 h-12 rounded-full p-0 object-cover text-[#E7831D] mb-3 sm:mb-0 bg-gray-300 animate-pulse mt-3"></div>
            ) : (
              <div className="flex justify-start w-full">
                <img
                  className={`rounded-full p-0 justify-start text-[#E7831D] cursor-pointer object-cover w-12 h-12 ${
                    userDetails.type === "employer"
                      ? "ring-2 ring-[#E7831D]"
                      : "ring-2 ring-gray-700"
                  }`}
                  src={
                    userAvatar !== "default.png" && userAvatar
                      ? `https://bocaendboca.s3.us-east-2.amazonaws.com/${userAvatar}`
                      : avatar
                  }
                  alt="Avatar"
                />

                <div className="ml-3">
                  <h1 className={`text-lg font-bold text-[#5B5B5B]`}>
                    {username}
                  </h1>
                  <div className="flex space-x-2">
                    <span>{userDetails.username}</span>
                    <span
                      className={`lg:text-sm md:text-sm text-xs  flex items-center ${
                        userDetails.type === "job-seeker"
                          ? "text-black"
                          : "text-[#E7831D]"
                      }`}
                    >
                      <GoDotFill className="ml-1 text-xs mr-1" />
                      {capitalizeFirstLetter(userDetails.type)}
                      <IoIosCheckmarkCircle className="ml-1 text-lg" />
                    </span>
                  </div>
                </div>
              </div>
            )}
          </div>

          <textarea
            type="text"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            placeholder={texts.descriptionPlaceholder}
            className="border-0 outline-none p-5 w-full z-50 bg-transparent px-[3.7rem] scrollbar-hide max-h-96"
          />

          <div className="flex flex-wrap mt-3 gap-2 px-[3.7rem]">
            {mediaFiless.map((media, index) => {
              const mediaURL = media.isFile
                ? URL.createObjectURL(media.file) // For newly uploaded files
                : `https://bocaendboca.s3.us-east-2.amazonaws.com/${media.file}`; // For existing files

              return (
                <div key={index} className="relative">
                  {media.type === "image" ? (
                    <img
                      src={mediaURL}
                      alt={`upload-${index}`}
                      className=" object-cover w-36 h-36 rounded-md"
                    />
                  ) : media.type === "video" ? (
                    <video
                      src={mediaURL}
                      controls
                      className="w-20 h-20 object-cover rounded-md"
                    />
                  ) : null}
                  <FaTimes
                    onClick={() => removeMediaFile(index)}
                    className="absolute text-2xl top-1 right-1 text-white cursor-pointer bg-red-600 rounded-full p-1"
                  />
                </div>
              );
            })}
          </div>

          <div className="flex w-full h-auto mt-5 justify-end items-center gap-4">
            <div className="flex items-center">
              <input
                type="file"
                accept="image/*,video/*"
                className="hidden"
                id="media-uploade"
                onChange={handleMediaUploade}
                multiple
              />
              <label htmlFor="media-uploade">
                <MdOutlineAddPhotoAlternate className="text-2xl text-gray-500 cursor-pointer" />
              </label>
            </div>

            <button
              onClick={handleSubmit}
              className="bg-[#404041] p-3 text-center text-white font-bold rounded-full px-10 hover:bg-[#545455] hover:scale-95 text-lg"
              disabled={loading}
            >
              Update
            </button>
          </div>
        </div>
      </Modal>

      <Toaster richColors closeButton />
    </>
  );
};

export default EditPostModalComponent;
