import React, { useEffect, useState, useCallback, useRef } from "react";
import PublishPost from "./PublishPost";
import Posts from "./Posts";
import { Toaster, toast } from "sonner";
import axios from "axios";
import Loading from "../loading/Loading";
import PostShimmer from "../loading/PostShimmer";
import { useSelector } from "react-redux";
import debounce from "lodash.debounce";
import { useNavigate } from "react-router-dom";
import translations from "../../languages";
import GuestModeModal from "../../pages/GuestModeModal";
import SmallSpinner from "../loading/SmallSpinner";
import UserList from "../mini-components/UserList";
import bblogo from "../../assets/276pxlogo.svg";
import mobilebarsvg from "../../assets/barsvgmobtab.svg";
const HomeMain = ({ setSidebarOpen }) => {
  const scrollContainerRef = useRef(null);
  const navigate = useNavigate();
  const [postdatas, setPosts] = useState([]);

  const [loading, setLoading] = useState(true);
  const [fetching, setFetching] = useState(false);
  const [totalPosts, setTotalPosts] = useState(null);

  const [isLoginModalVisible, setIsLoginModalVisible] = useState(false);
  const [bookmarkedPosts, setBookmarkedPosts] = useState(new Set());
  const [filteredPostCount, setFilteredPostCount] = useState(0);
  console.log(filteredPostCount, "filteredPostCount");
  const [userExists, setUserExists] = useState(false);
  const searchText = useSelector((state) => state.search.searchText);
  const activeTags = useSelector((state) => state.tag.activeTags);
  const [postLimit, setPostLimit] = useState(10);
  const lastApiCallTime = useRef(0);
  const [channelLoading, setChannelLoading] = useState(false);
  const selectedLanguage = useSelector((state) => state.language.language);

  const texts = translations[selectedLanguage].headings;

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      setUserExists(true);
    } else {
      setUserExists(false);
      toast(
        " You are currently in guest mode. Some features are limited. To unlock the full functionality, please log in or create a free account.",
        {
          style: {
            backgroundColor: "#333", // Gray background
            color: "#fff", // White text
          },
        }
      );
    }
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (scrollContainerRef.current) {
        const { scrollTop, scrollHeight, clientHeight } =
          scrollContainerRef.current;
        if (scrollTop + clientHeight >= scrollHeight) {
          console.log("Reached the bottom!");
        }
      }
    };

    const scrollContainer = scrollContainerRef.current;
    if (scrollContainer) {
      scrollContainer.addEventListener("scroll", handleScroll);
    }

    // Cleanup the event listener on component unmount
    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  const fetchPosts = useCallback(
    async (reset = false) => {
      const currentTime = Date.now();
      if (currentTime - lastApiCallTime.current < 1000) {
        return; // If the last API call was made less than 1 second ago, do nothing
      }
      lastApiCallTime.current = currentTime;

      setLoading(true);
      try {
        const baseURL = process.env.REACT_APP_API_BASE_URL;
        const token = localStorage.getItem("token");
        let url, params;

        const tagParams = {};
        activeTags.forEach((tag) => {
          switch (tag) {
            case "job_seeker_post":
              tagParams.job_seeker_post = 1;
              break;
            case "emp_profile":
              tagParams.emp_profile = 1;
              break;
            case "job_seeker_profile":
              tagParams.job_seeker_profile = 1;
              break;
            case "emp_post":
              tagParams.emp_post = 1;
              break;
            default:
              break;
          }
        });

        if (token) {
          url = `${baseURL}/api/v1/user/post/list`;
          params = {
            limit: postLimit,
            string: searchText,
            offset: 1,
            ...tagParams,
          };
        } else {
          url = `${baseURL}/api/v1/user/post/listoffline`;
          params = {
            limit: postLimit,
            string: searchText,
            offset: 1,
            ...tagParams,
          };
        }

        const response = await axios.get(url, {
          params,
          headers: token ? { Authorization: `Bearer ${token}` } : {},
        });

        const postDataStructure = response.data.content.data;
        console.log(postDataStructure, "postDataStructure");
        const total = response.data.content.total;

        setTotalPosts(total);
        setPosts(postDataStructure);
        if (Object.keys(tagParams).length > 0 || searchText.trim() !== "") {
          setFilteredPostCount(postDataStructure.length);
        } else {
          setFilteredPostCount(0);
        }

        // if (reset) {
        //   setPosts(postDataStructure);
        // } else {
        //   setPosts((prev) => {
        //     const newPosts = postDataStructure.filter(
        //       (post) =>
        //         !prev.some((existingPost) => existingPost.id === post.id)
        //     );
        //     return [...prev, ...newPosts];
        //   });
        // }

        setLoading(false);
        setFetching(false);
      } catch (err) {
        setLoading(false);
        setFetching(false);
      }
    },
    [postLimit, searchText, activeTags, totalPosts]
  );

  const debouncedFetchPosts = useCallback(
    debounce((reset) => {
      fetchPosts(reset);
    }, 300),
    [fetchPosts]
  );

  const handleInfiniteScroll = async () => {
    if (totalPosts && postdatas.length >= totalPosts) {
      return; //! Stop fetching more posts if we have already fetched all posts
    }

    try {
      if (
        window.innerHeight + document.documentElement.scrollTop + 1 >=
        document.documentElement.scrollHeight
      ) {
        if (!fetching) {
          setFetching(true);
          setPostLimit((prev) => prev + 10);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleFetchPosts = async () => {
    if (totalPosts && postdatas.length >= totalPosts) {
      return;
    }

    try {
      if (!fetching) {
        setFetching(true);
        setPostLimit((prevLimit) => prevLimit + 10);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      handleFetchPosts();
    }, 5000);

    return () => clearInterval(intervalId);
  }, [postdatas, fetching, totalPosts]);

  useEffect(() => {
    fetchPosts(true);
  }, [postLimit, fetchPosts, totalPosts]);

  useEffect(() => {
    if (searchText.length >= 3) {
      setPostLimit(10);
      debouncedFetchPosts(true);
    } else if (searchText.length === 0) {
      debouncedFetchPosts(true);
    }
  }, [searchText, debouncedFetchPosts]);

  useEffect(() => {
    const refreshScrollListener = () => {
      window.removeEventListener("scroll", handleInfiniteScroll);
      window.addEventListener("scroll", handleInfiniteScroll);
    };
    refreshScrollListener();
  }, [fetching]);

  useEffect(() => {
    if (searchText.length > 0) {
      navigate("/explore");
    }
  }, [searchText, navigate]);

  const handleBookmark = async (userId) => {
    try {
      const authToken = localStorage.getItem("token");
      await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/user/profile/bookmark/store`,
        { user_id: userId },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      toast.success("User bookmarked successfully");
    } catch (error) {
      console.error("Error bookmarking user:", error);
      toast.error("Error bookmarking user. Please try again later.");
    }
  };

  const handleReportPost = async (postId) => {
    try {
      const authToken = localStorage.getItem("token");
      await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/user/post/user-action`,
        { message: "block", post_id: postId, comment_type: "block" },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      toast.success("Post reported successfully");
      fetchPosts(true);
    } catch (error) {
      console.error("Error reporting post:", error);
      toast.error("Error reporting post. Please try again later.");
    }
  };
  const handleDeletePost = async (postId) => {
    try {
      const authToken = localStorage.getItem("token");
      await axios.delete(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/user/post/delete/${postId}`,

        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      toast.success("Post deleted successfully");
      fetchPosts(true);
    } catch (error) {
      console.error("Error deleted post:", error);
      toast.error("Error deleted post. Please try again later.");
    }
  };

  const createChatChannel = async (postId, toUser) => {
    try {
      setChannelLoading((prev) => ({ ...prev, [postId]: true }));
      const authToken = localStorage.getItem("token");
      const url = `${process.env.REACT_APP_API_BASE_URL}/api/v1/customer/chat/create-channel`;
      const data = {
        reference_id: "",
        reference_type: "support",
        post_id: postId,
        to_user: toUser,
      };

      const response = await axios.post(url, data, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });

      const channelId = response.data.content.id;

      setChannelLoading((prev) => ({ ...prev, [postId]: false }));

      toast.success("Chat channel created successfully");

      navigate(`/dm/${channelId}/${toUser}`);
    } catch (error) {
      setChannelLoading((prev) => ({ ...prev, [postId]: false }));
      toast.error("Error creating chat channel. Please try again later.");
    }
  };

  return (
    <div className=" w-full paddingy mobile-top " ref={scrollContainerRef}>
      <div className="flex items-center justify-between mb-3 w-full relative bg-white sticky-top">
        <div className="absolute left-0">
          <img
            src={mobilebarsvg}
            alt="logo"
            className="w-8 cursor-pointer hidelarge bottom-navigation ml-5"
            onClick={() => setSidebarOpen(true)}
          />
        </div>
        <div className="flex justify-center w-full">
          <img src={bblogo} className="h-8 hidelarge" />
        </div>
      </div>

      <h1 className="text-lg font-bold mb-2 px-8 sidebar-complete-hide ">
        {texts.homepage}
      </h1>
      {filteredPostCount > 0 && (
        <p className=" italic text-gray-600 px-8 text-xs">
          {filteredPostCount > 0 ? `${filteredPostCount} results found` : ""}
        </p>
      )}
      <hr />
      <PublishPost
        fetchPosts={fetchPosts}
        setLoadingpost={setLoading}
        userExists={userExists}
      />
      <hr />
      <UserList />
      <h2 className="text-lg font-bold mt-1 px-8 mb-2">{texts.latestPosts}</h2>
      {loading && !fetching ? (
        <div className="flex flex-col">
          <PostShimmer />
          <PostShimmer />
          <PostShimmer />
        </div>
      ) : (
        <Posts
          postdatas={postdatas}
          loading={loading}
          handleBookmark={handleBookmark}
          handleReportPost={handleReportPost}
          createChatChannel={createChatChannel}
          channelLoading={channelLoading}
          handleDeletePost={handleDeletePost}
          setFetching={setFetching}
          fetchPosts={fetchPosts}
          setLoadingpost={setLoading}
          setPostLimit={setPostLimit}
        />
      )}
      {/* 
       {!userExists && (
        <GuestModeModal
          visible={isLoginModalVisible}
          onCancel={() => setIsLoginModalVisible(false)}
        />
      )}  */}

      {fetching && <SmallSpinner />}
    </div>
  );
};

export default HomeMain;
