import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { IoCameraOutline } from "react-icons/io5";
import { ImAttachment } from "react-icons/im";
import { IoIosSend } from "react-icons/io";
import { AiFillCloseCircle } from "react-icons/ai";
import { FilePdfOutlined, FileWordOutlined } from "@ant-design/icons";
import SingleChat from "./SingleChat";
import ChatPerson from "./ChatPerson";
import { useDispatch, useSelector } from "react-redux";
import translations from "../../languages";
import ChatPersonDirect from "./ChatPersonDirect";

const ChatComponentdirect = ({
  Channel_details,
  OpenChat,
  userDetails,
  postDescriptionall,
}) => {
  const [conversationData, setConversationData] = useState([]);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const [sendingMsg, setSendingMsg] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);

  const fileInputRef = useRef(null);

  const selectedLanguage = useSelector((state) => state.language.language);

  const options = translations[selectedLanguage].actions;
  const fetchConversation = async () => {
    try {
      setLoading(true);
      const baseUrl = process.env.REACT_APP_API_BASE_URL;
      const token = localStorage.getItem("token");
      const channelId = localStorage.getItem("channelId");

      const response = await axios.get(
        `${baseUrl}/api/v1/customer/chat/conversation?limit=100&channel_id=${Channel_details.id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setConversationData(response.data.content.conversation);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching conversation:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: "smooth",
    });
  }, []);
  useEffect(() => {
    const notichannelId = localStorage.getItem("notichannelId");
    if (notichannelId) {
      localStorage.removeItem("notichannelId");
    }

    fetchConversation();
    const intervalId = setInterval(fetchConversation, 5000);

    return () => clearInterval(intervalId);
  }, []);

  const handleSendMessage = async () => {
    try {
      setSendingMsg(true);
      const baseUrl = process.env.REACT_APP_API_BASE_URL;
      const token = localStorage.getItem("token");
      const channelId = localStorage.getItem("channelId");

      const formData = new FormData();
      formData.append("message", message);
      formData.append("channel_id", Channel_details.id);

      selectedFiles.forEach((file) => {
        formData.append("files[]", file);
      });

      const response = await axios.post(
        `${baseUrl}/api/v1/customer/chat/send-message`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      setMessage("");
      setSelectedFiles([]);
      fetchConversation();
      setSendingMsg(false);
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    setSelectedFiles((prevFiles) => [...prevFiles, ...files]);
  };

  const handleRemoveFile = (index) => {
    setSelectedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const renderFilePreview = (file) => {
    const fileType = file.type.split("/")[1];
    if (file.type.startsWith("image/")) {
      return (
        <img
          src={URL.createObjectURL(file)}
          alt="Preview"
          className="object-cover rounded-lg h-full"
          style={{ maxWidth: "100px", maxHeight: "100px" }}
        />
      );
    } else if (fileType === "pdf") {
      return (
        <div className="flex items-center bg-white bg-opacity-30 p-2 rounded-md">
          <FilePdfOutlined className="text-red-500 mr-2" />
          {file.name}
        </div>
      );
    } else if (
      fileType === "msword" ||
      fileType === "vnd.openxmlformats-officedocument.wordprocessingml.document"
    ) {
      return (
        <div className="flex items-center bg-white bg-opacity-30 p-2 rounded-md">
          <FileWordOutlined className="text-blue-500 mr-2" />
          {file.name}
        </div>
      );
    } else {
      return (
        <div className="flex items-center bg-white bg-opacity-30 p-2 rounded-md">
          <ImAttachment className="mr-2" />
          {file.name}
        </div>
      );
    }
  };

  return (
    <div className="w-full flex flex-col h-screen heightchat  ">
      <div>
        <hr />
        <ChatPersonDirect
          userDetails={userDetails}
          postDescriptionall={postDescriptionall}
        />
      </div>
      <SingleChat conversationData={conversationData} />
      {/* <div ref={endOfMessagesRef} /> */}
      <div className="w-auto flex flex-col mt-auto">
        <div className="w-full flex flex-wrap justify-center bg-black/30">
          {selectedFiles.map((file, index) => (
            <div key={index} className="relative m-2">
              {renderFilePreview(file)}
              <AiFillCloseCircle
                className="absolute top-0 right-0 text-xl cursor-pointer text-red-500"
                onClick={() => handleRemoveFile(index)}
              />
            </div>
          ))}
        </div>
        <div className="w-full fixed"></div>
        <div className="  bottom-10  md:w-full w-full bg-white border-t-2 pt-8 justify-center items-center space-x-5 flex lg:mx-0 mx-0 md:mx-0 z-50 mb-2 pb-5  ">
          <input
            type="file"
            ref={fileInputRef}
            multiple
            style={{ display: "none" }}
            onChange={handleFileChange}
          />
          <ImAttachment
            className="text-xl cursor-pointer"
            onClick={() => fileInputRef.current.click()}
          />
          <div className="w-10/12 h-10 rounded-full bg-gray-100 ml-auto flex">
            <input
              type="text"
              placeholder="type here."
              className="p-2 w-10/12 bg-transparent outline-none "
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  handleSendMessage();
                }
              }}
            />
            <div
              className="bg-[#E7831D] p-2 rounded-full ml-auto hover:scale-95 hover:bg-orange-400 cursor-pointer"
              onClick={handleSendMessage}
            >
              {sendingMsg ? (
                <div class="flex flex-row justify-center items-center text-center gap-2 mt-2">
                  <div class="w-2 h-2 rounded-full bg-gray-200 animate-bounce [animation-delay:.7s]"></div>
                  <div class="w-2 h-2 rounded-full bg-gray-200 animate-bounce [animation-delay:.3s]"></div>
                </div>
              ) : (
                <IoIosSend className="text-white text-2xl" />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatComponentdirect;
