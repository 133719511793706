import React, { useEffect, useRef } from "react";
import { IoClose } from "react-icons/io5";
import { Modal, Input } from "antd";
import { Toaster, toast } from "sonner";
import QRCodeStyling from "qr-code-styling"; // Import QRCodeStyling
import centerimg from "../../assets/favicon.jpg";

const ShareProfilePopUp = ({
  profileShareModalVisible,
  setProfileShareModalVisible,
  profileUrl,
}) => {
  const qrCodeRef = useRef(null);

  useEffect(() => {
    const qrCode = new QRCodeStyling({
      width: 200,
      height: 200,
      data: profileUrl,
      image:
        "https://img.freepik.com/free-vector/bird-colorful-logo-gradient-vector_343694-1365.jpg", // Add your logo here
      dotsOptions: {
        color: "#000",
        type: "rounded",
      },
      backgroundOptions: {
        color: "#ffffff",
      },
      imageOptions: {
        crossOrigin: "anonymous",
        margin: 10,
      },
    });

    qrCode.append(qrCodeRef.current); // Append the QR to the ref div
  }, [profileUrl]);

  const handleCopy = () => {
    navigator.clipboard.writeText(profileUrl);
    toast.success("Profile URL copied to clipboard! 🎉");
    setProfileShareModalVisible(false);
  };

  return (
    <>
      <Toaster position="top-right" />
      <Modal
        visible={profileShareModalVisible}
        footer={null}
        onCancel={() => setProfileShareModalVisible(false)}
        centered
        closeIcon={<IoClose className="text-gray-50" />}
        className="custom-modal"
      >
        <div className="flex flex-col space-y-5 items-center text-center lg:p-8 md:p-6 p-4">
          <p className="text-lg font-medium text-gray-600">
            Share this profile! Copy the link or scan the code.
          </p>
          {/* QR Code */}
          <div ref={qrCodeRef} className="mb-5" />
          {/* URL Input */}
          <Input
            value={profileUrl}
            disabled
            readOnly
            className="text-center bg-gray-100 rounded-lg border-none focus:ring-2 focus:ring-gray-300"
            style={{ width: "100%" }}
          />
          {/* Copy Button */}
          <button
            className="p-3 bg-[#404041] text-white font-bold rounded-full shadow-lg text-md transition-transform transform hover:scale-105 hover:shadow-xl w-1/2"
            onClick={handleCopy}
          >
            Copy Profile URL
          </button>
        </div>
      </Modal>
    </>
  );
};

export default ShareProfilePopUp;
