import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import ProfileBanner from "../../profile/ProfileBanner";
import MainLogoLoader from "../loading/MainLogoLoader";
import Postscopy from "../homepage/Postscopy";
import UserProfileBanner from "../../profile/UserProfileBanner";
import { useDispatch, useSelector } from "react-redux";
import translations from "../../languages";
import UserList from "../mini-components/UserList";
import bblogo from "../../assets/276pxlogo.svg";
import { Toaster, toast } from "sonner";
import mobilebarsvg from "../../assets/barsvgmobtab.svg";
const UserProfile = ({ setSidebarOpen }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [userDetails, setUserDetails] = useState(null);
  const searchText = useSelector((state) => state.search.searchText);
  const [postList, setPostList] = useState([]);
  const selectedLanguage = useSelector((state) => state.language.language);
  const texts = translations[selectedLanguage].headings;
  const [isMyProfile, setIsMyProfile] = useState(false);
  const [channelLoading, setChannelLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        // Get base URL from environment variable
        const baseURL = process.env.REACT_APP_API_BASE_URL;
        // Get token and userId from local storage
        const token = localStorage.getItem("token");
        const userId = localStorage.getItem("userId");

        // Fetch user details
        let userDetailsResponse;
        if (userId) {
          // If userId exists in localStorage, use the standard API for user details
          userDetailsResponse = await axios.get(
            `${baseURL}/api/v1/user/details/${id}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
        } else {
          // If userId is not in localStorage, use the "share" API for user details
          userDetailsResponse = await axios.get(
            `${baseURL}/api/v1/user/details/share/${id}`
          );
        }

        const fetchedUserDetails = userDetailsResponse.data.content;
        setUserDetails(fetchedUserDetails);

        // Check if the fetched user ID matches the stored user ID
        if (fetchedUserDetails.id === userId) {
          setIsMyProfile(true);
        }

        // Fetch post list
        let postListResponse;
        if (userId) {
          // If userId exists, use the standard post list API
          postListResponse = await axios.get(
            `${baseURL}/api/v1/user/post/list?limit=100&offset=1&user_id=${id}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
        } else {
          // If userId is not in localStorage, use the offline post list API
          postListResponse = await axios.get(
            `${baseURL}/api/v1/user/post/listoffline?limit=100&offset=1&user_id=${id}`
          );
        }

        setPostList(postListResponse.data.content.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching user details and post list:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  useEffect(() => {
    if (searchText.length > 0) {
      navigate("/explore");
    }
  }, [searchText, navigate]);
  const createChatChannel = async (postId, toUser) => {
    try {
      setChannelLoading((prev) => ({ ...prev, [postId]: true }));
      const authToken = localStorage.getItem("token");
      const url = `${process.env.REACT_APP_API_BASE_URL}/api/v1/customer/chat/create-channel`;
      const data = {
        reference_id: "",
        reference_type: "support",
        post_id: postId,
        to_user: toUser,
      };

      const response = await axios.post(url, data, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });

      const channelId = response.data.content.id;

      setChannelLoading((prev) => ({ ...prev, [postId]: false }));

      toast.success("Chat channel created successfully");

      navigate(`/dm/${channelId}/${toUser}`);
    } catch (error) {
      setChannelLoading((prev) => ({ ...prev, [postId]: false }));
      toast.error("Error creating chat channel. Please try again later.");
    }
  };
  return (
    <div className="scrollbar-hide w-full paddingy mobile-top">
      <div className="flex items-center justify-between mb-3 w-full relative bg-white sticky-top">
        <div className="absolute left-0">
          <img
            src={mobilebarsvg}
            alt="logo"
            className="w-8 cursor-pointer hidelarge bottom-navigation ml-5"
            onClick={() => setSidebarOpen(true)}
          />
        </div>
        <div className="flex justify-center w-full">
          <img src={bblogo} className="h-8 hidelarge" />
        </div>
      </div>

      {!loading && (
        <h1 className="text-lg font-bold mb-2 px-8 sidebar-complete-hide">
          {isMyProfile ? texts.myProfile : texts.profile}
        </h1>
      )}

      <hr />

      {loading ? (
        <MainLogoLoader />
      ) : (
        userDetails && (
          <UserProfileBanner
            userDetails={userDetails}
            isMyProfile={isMyProfile}
          />
        )
      )}
      <hr />
      {/* Render post list here */}

      <UserList />
      <div className="mt-10">
        <Postscopy
          postdatas={postList}
          createChatChannel={createChatChannel}
          channelLoading={channelLoading}
        />
      </div>
    </div>
  );
};

export default UserProfile;
