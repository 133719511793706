import React, { useState, useEffect } from "react";
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Transition,
} from "@headlessui/react";
import { IoIosCheckmarkCircle } from "react-icons/io";
import moment from "moment";
import { BsThreeDots } from "react-icons/bs";
import { GoDotFill } from "react-icons/go";
import avatar from "../../assets/lady.jpg";
import { IoIosArrowRoundBack } from "react-icons/io";
import { Link } from "react-router-dom";

const ChatPerson = ({
  chatData,
  OpenChat,
  options,
  blockOrReportUser,
  deleteChats,
}) => {
  const [userLastmsg, setUserLastMessage] = useState("");
  const [expandedMessages, setExpandedMessages] = useState({});
  console.log(chatData, "chatData");
  const capitalizeFirstLetter = (string) => {
    if (!string) return "";
    const formattedString = string.replace(/-/g, " ");
    return formattedString.charAt(0).toUpperCase() + formattedString.slice(1);
  };

  useEffect(() => {
    const msg = localStorage.getItem("postDescription");
    if (msg) {
      setUserLastMessage(msg);
    } else return;
  }, []);

  const formatMessage = (message) => {
    return message.replace(
      /#(\w+)/g,
      '<span class="font-bold text-[#E7831D]">#$1</span>'
    );
  };

  const truncateMessage = (message, length) => {
    if (message.length <= length) return message;
    return `${message.slice(
      0,
      length
    )}... <span class="text-orange-400 cursor-pointer">Read more</span>`;
  };

  const toggleMessage = (index) => {
    setExpandedMessages((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };
  const handleAction = async (actionType, userId) => {
    try {
      const result = await blockOrReportUser(userId, actionType);
      console.log(`${actionType} action successful:`, result);
    } catch (error) {
      console.error(`Error performing ${actionType} action:`, error);
    }
  };
  return (
    <div>
      <IoIosArrowRoundBack
        className="mt-2 ml-8 text-3xl cursor-pointer hover:scale-95"
        onClick={() => OpenChat(false)}
      />
      {chatData.map((post, index) => (
        <div key={index} className="py-3 bg-white rounded-xl mb-3 w-full px-8">
          <div className="flex">
            <Link to={`/user/${post.user.id}`}>
              <img
                className={`w-12 h-12 rounded-full object-cover text-[#E7831D] ${
                  post.user.type === "employer"
                    ? "ring-2 ring-[#E7831D]"
                    : "ring-2 ring-gray-700"
                }`}
                src={
                  post.user.profile_image !== "default.png" &&
                  post.user.profile_image
                    ? `https://bocaendboca.s3.us-east-2.amazonaws.com/${post.user.profile_image}`
                    : avatar
                }
                alt="User Avatar"
              />
            </Link>

            <div className="flex flex-col ml-2">
              <Link to={`/user/${post.user.id}`}>
                <span className="text-lg font-bold text-gray-600 cursor-pointer">
                  {post.user.first_name} {post.user.last_name}
                </span>
              </Link>

              <span
                className={`text-sm flex items-center ${
                  post.user.type === "job-seeker"
                    ? "text-black"
                    : "text-[#E7831D]"
                }`}
              >
                <span
                  className={`text-sm text-[#5B5B5B] ${
                    post.user.username === "@premium" ||
                    post.user.username === "@Test"
                      ? "text-[#5B5B5B]"
                      : ""
                  }`}
                >
                  {post.user.username}
                </span>
                <GoDotFill className="ml-1 text-xs mr-1" />
                {capitalizeFirstLetter(post.user.type)}
                <IoIosCheckmarkCircle className="ml-1 text-lg" />
              </span>
            </div>
            <div className="flex justify-end text-xs flex-col ml-auto relative">
              <Menu>
                <MenuButton>
                  <BsThreeDots className="text-3xl ml-auto cursor-pointer" />
                </MenuButton>
                <Transition
                  enter="transition ease-out duration-75"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <MenuItems
                    anchor="bottom end"
                    className="w-auto origin-top-right rounded-xl border border-black/30 bg-white p-1 text-sm/6 text-black focus:outline-none shadow-md"
                  >
                    <MenuItem>
                      {({ active }) => (
                        <button
                          className="group flex rounded-md items-left w-full px-2 py-2 text-sm hover:bg-gray-700 hover:text-white justify-start text-center whitespace-nowrap"
                          onClick={() => handleAction("block", post.user.id)}
                        >
                          {options.blockUser}
                        </button>
                      )}
                    </MenuItem>
                    <MenuItem>
                      {({ active }) => (
                        <button
                          className="group flex rounded-md items-left w-full px-2 py-2 text-sm hover:bg-gray-700 hover:text-white justify-start text-center whitespace-nowrap"
                          onClick={() => handleAction("report", post.user.id)}
                        >
                          {options.reportUser}
                        </button>
                      )}
                    </MenuItem>
                    <MenuItem>
                      {({ active }) => (
                        <button
                          className="group flex rounded-md items-left w-full px-2 py-2 text-sm hover:bg-gray-700 hover:text-white justify-start text-center whitespace-nowrap"
                          onClick={() => deleteChats(post.channel_id)}
                        >
                          {options.deleteChat}
                        </button>
                      )}
                    </MenuItem>
                  </MenuItems>
                </Transition>
              </Menu>
            </div>
          </div>
          <div className="mt-3 ml-14 mb-3">
            <div
              className="message-content"
              onClick={() => toggleMessage(index)}
              dangerouslySetInnerHTML={{
                __html: formatMessage(
                  expandedMessages[index]
                    ? userLastmsg
                    : truncateMessage(userLastmsg, 100)
                ),
              }}
            ></div>
            {expandedMessages[index] && (
              <span
                className="text-orange-400 cursor-pointer"
                onClick={() => toggleMessage(index)}
              >
                ...Read less
              </span>
            )}
          </div>
          <hr />
        </div>
      ))}
    </div>
  );
};

export default ChatPerson;
