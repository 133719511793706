import React from "react";

const PostDescription = ({ description, handleTagClick }) => {
  return (
    <div className="mt-5 mx-[5.5rem]">
      {description.split("\n").map((line, index) => (
        <React.Fragment key={index}>
          {line.split(/(\s+)/).map((part, i) =>
            part.startsWith("#") ? (
              <span
                key={i}
                className="text-[#E7831D] font-bold cursor-pointer transition-transform ease-in-out duration-300 text-sm sm:text-base md:text-md"
                onClick={() => handleTagClick(part)}
              >
                {part}
              </span>
            ) : (
              <span key={i} className="text-sm sm:text-base md:text-md">
                {part}
              </span>
            )
          )}
          <br />
        </React.Fragment>
      ))}
    </div>
  );
};

export default PostDescription;
