import React from "react";
import { Modal } from "antd";
import { IoArrowBack, IoArrowForward } from "react-icons/io5";
import { IoClose } from "react-icons/io5";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { FaUserCircle } from "react-icons/fa";
import { GoDotFill } from "react-icons/go";
import { Carousel } from "react-responsive-carousel";
import { Link } from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Import carousel styles

const MediaModal = ({ isModalOpen, closeModal, modalData, handleTagClick }) => {
  const capitalizeFirstLetter = (string) => {
    if (!string) return "";

    const formattedString = string.replace(/-/g, " ");

    return formattedString
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };
  return (
    <Modal
      visible={isModalOpen}
      onCancel={closeModal}
      footer={null}
      style={{ width: "100%", resize: "auto" }}
      closeIcon={<IoClose className="text-gray-50" />}
    >
      {modalData && (
        <div className="flex flex-col w-full ">
          <div className="w-full p-4">
            {modalData.images && modalData.images.length === 1 ? (
              <img
                src={`https://bocaendboca.s3.us-east-2.amazonaws.com/${modalData.images[0].file}`}
                className="rounded-xl w-full max-h-[30rem] object-cover"
                alt="Post"
              />
            ) : modalData.images && modalData.images.length > 1 ? (
              <Carousel
                showThumbs={false}
                showStatus={false}
                renderArrowPrev={(onClickHandler, hasPrev) =>
                  hasPrev && (
                    <button
                      onClick={onClickHandler}
                      className="absolute top-1/2 left-4 transform -translate-y-1/2 bg-white rounded-full p-2 shadow-md text-gray-700 hover:bg-gray-200 transition duration-300 z-50"
                    >
                      <IoArrowBack size={24} />
                    </button>
                  )
                }
                renderArrowNext={(onClickHandler, hasNext) =>
                  hasNext && (
                    <button
                      onClick={onClickHandler}
                      className="absolute top-1/2 right-4 transform -translate-y-1/2 bg-white rounded-full p-2 shadow-md text-gray-700 hover:bg-gray-200 transition duration-300"
                    >
                      <IoArrowForward size={24} />
                    </button>
                  )
                }
              >
                {modalData.images.map((image, index) => (
                  <div key={index} className="w-full flex">
                    <img
                      src={`https://bocaendboca.s3.us-east-2.amazonaws.com/${image.file}`}
                      className="rounded-xl w-1/2 max-h-[30rem] object-cover"
                      alt={`Post Image ${index + 1}`}
                    />
                  </div>
                ))}
              </Carousel>
            ) : modalData.videos && modalData.videos.length === 1 ? (
              <video
                src={`https://bocaendboca.s3.us-east-2.amazonaws.com/${modalData.videos[0].file}`}
                controls
                className="rounded-xl w-1/2 max-h-[30rem] object-scale-down"
              />
            ) : modalData.videos && modalData.videos.length > 1 ? (
              <Carousel
                showThumbs={false}
                showStatus={false}
                renderArrowPrev={(onClickHandler, hasPrev) =>
                  hasPrev && (
                    <button
                      onClick={onClickHandler}
                      className="absolute top-1/2 left-4 transform -translate-y-1/2 bg-white rounded-full p-2 shadow-md text-gray-700 hover:bg-gray-200 transition duration-300 z-50"
                    >
                      <IoArrowBack size={24} />
                    </button>
                  )
                }
                renderArrowNext={(onClickHandler, hasNext) =>
                  hasNext && (
                    <button
                      onClick={onClickHandler}
                      className="absolute top-1/2 right-4 transform -translate-y-1/2 bg-white rounded-full p-2 shadow-md text-gray-700 hover:bg-gray-200 transition duration-300"
                    >
                      <IoArrowForward size={24} />
                    </button>
                  )
                }
              >
                {modalData.videos.map((video, index) => (
                  <div key={index} className="w-full flex">
                    <video
                      src={`https://bocaendboca.s3.us-east-2.amazonaws.com/${video.file}`}
                      controls
                      className="rounded-xl w-full max-h-[30rem] object-scale-down"
                    />
                  </div>
                ))}
              </Carousel>
            ) : null}
          </div>
          <div className="w-full p-4 flex flex-col justify-between">
            <div>
              <div className="flex items-center mb-4 mt-2">
                {modalData.user.profile_image !== "default.png" && (
                  <img
                    className={`w-12 h-12 rounded-full p-0 object-cover text-[#E7831D] ${
                      modalData.user.type === "employer"
                        ? "ring-2 ring-[#E7831D]"
                        : "ring-2 ring-gray-700"
                    }`}
                    src={`https://bocaendboca.s3.us-east-2.amazonaws.com/${modalData.user.profile_image}`}
                    alt="User Avatar"
                    style={{ aspectRatio: "1 / 1", objectFit: "cover" }}
                  />
                )}
                {modalData.user.profile_image === "default.png" && (
                  <FaUserCircle
                    className={`w-12 h-12 text-[2rem] rounded-full p-0 object-cover text-gray-300 ${
                      modalData.user.type === "employer"
                        ? "ring-2 ring-[#E7831D]"
                        : "ring-2 ring-gray-700"
                    }`}
                  />
                )}
                <div className="flex flex-col ml-2">
                  <Link
                    to={`/user/${modalData.user.id}`}
                    className="text-lg font-bold text-[#5B5B5B]"
                  >
                    {modalData.user.first_name} {modalData.user.last_name}
                  </Link>
                  <span className="text-sm flex items-center">
                    <span className="text-sm text-[#5B5B5B]">
                      {modalData.user.username}
                    </span>
                    <GoDotFill
                      className={`ml-1 text-xs mr-1 ${
                        modalData.user.type === "job-seeker"
                          ? "text-black"
                          : "text-[#E7831D]"
                      }`}
                    />
                    <span
                      className={`ml-1 text-sm mr-1 ${
                        modalData.user.type === "job-seeker"
                          ? "text-black"
                          : "text-[#E7831D]"
                      }`}
                    >
                      {capitalizeFirstLetter(modalData.user.type)}
                    </span>
                    <IoIosCheckmarkCircle
                      className={`ml-1 text-lg mr-1 ${
                        modalData.user.type === "job-seeker"
                          ? "text-black"
                          : "text-[#E7831D]"
                      }`}
                    />
                  </span>
                </div>
              </div>
              <p className="ml-14 text-md w-fit mb-5">
                {modalData.description.split("\n").map((line, index) => (
                  <React.Fragment key={index}>
                    {line.split(/(\s+)/).map((part, i) =>
                      part.startsWith("#") ? (
                        <span
                          key={i}
                          className="text-[#E7831D] font-bold cursor-pointer transition-transform ease-in-out duration-300"
                          onClick={() => handleTagClick(part)}
                        >
                          {part}
                        </span>
                      ) : (
                        part
                      )
                    )}
                    <br />
                  </React.Fragment>
                ))}
              </p>
            </div>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default MediaModal;
